<template>
  <div class="login">
    <div class="login_main">
      <div class="login_logo">
        <img src="~@/assets/images/logo2.png" alt="" />
      </div>
      <div class="login_info">
        <div class="title">《微短剧大赛》管理系统</div>
        <div class="input">
          <div class="items">
            <i class="el-icon-user"></i>
            <el-input
              placeholder="请输入用户名称"
              v-model="memeber"
              type="text"
            >
            </el-input>
          </div>
          <div class="items">
            <i class="el-icon-lock"></i>
            <el-input
              placeholder="请输入登录密码"
              v-model="password"
              type="password"
            >
            </el-input>
          </div>
          <!-- <div class="items" style="border:none;justify-content: flex-end;">
            <el-checkbox v-model="checked">记住我</el-checkbox>
          </div> -->
        </div>
        <div class="button">
          <el-button @click="clicklogin" type="primary">登录</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from "md5";
export default {
  data() {
    return {
      // memeber: '13700000002', // 登录填写用户名
      // password: 'esmAdmin@2024!', // 登录填写密码
      memeber: "", // 登录填写用户名
      password: "", // 登录填写密码
      checked: false, // 登录记住用户状态
    };
  },
  methods: {
    // 登录
    clicklogin() {
      // this.$router.push('/layerPage/user')
      let params = {
        account: this.memeber,
        password: md5(this.password),
      };
      this.$axiosPost("/manager/login", params).then((res) => {
        console.log(res);
        if (res.code == 0) {
          localStorage.setItem("access_token", res.data.token);
          localStorage.setItem("managerAuth", res.data.managerAuth);
          this.$router.push("/layerPage/user");
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
  },
  mounted() {
    if (localStorage.getItem("userinfo")) {
      let userinfo = JSON.parse(localStorage.getItem("userinfo"));
      if (userinfo.checked) {
        this.checked = userinfo.checked;
        this.memeber = userinfo.memeber;
        this.password = userinfo.password;
      }
    }
    // 当前页面禁止浏览器点击后退
    history.pushState(null, null, document.URL);
    window.addEventListener(
      "popstate",
      function () {
        history.pushState(null, null, document.URL);
      },
      false
    );
  },
};
</script>

<style lang="less" scoped>
@import "./login.less";
</style>